<template>
  <div class="prisma-users">
    <HeadSection :title="currentTitle" :permissionOfSection="permissionOfSection" :breadcrumbs="currentBreadcrumbs"></HeadSection>
    <MainContainer :loading="userLoading" permissionOfSection="prisma_users_info">
      <component :is="currentPage.component" :paymentsView="paymentsViewPermission" :paymentsEdit="paymentsEditPermission" :editPermission="permissionEdit"
        :userLogin="userLogin" :userUUID="userUUID" :userTaskId="userTaskId" :taskId="taskId" :prizeId="prizeId" />
    </MainContainer>
  </div>
</template>
<script>
import HeadSection from '@/components/main/headSection'
import MainContainer from '@/components/main/mainContainer'
import Users from '@/views/apps/prisma/components/tables/users.vue'
import UserTransactions from '@/views/apps/prisma/components/tables/userTransactions.vue'
import UserBets from '@/views/apps/prisma/components/tables/userBets.vue'
import UserPrizes from '@/views/apps/prisma/components/tables/userPrizes.vue'
import UserTasks from '@/views/apps/prisma/components/tables/userTasks.vue'
import TaskDetails from '@/views/apps/prisma/components/tables/taskDetails.vue'
import PrizeLogs from '@/views/apps/prisma/components/tables/prizeLogs.vue'
import accessMix from '@/services/accessmix.js'
import { mapState } from 'vuex'

export default {
  name: 'prismaMain',
  components: { HeadSection, MainContainer, Users, UserTransactions, UserBets, UserTasks, UserPrizes, TaskDetails, PrizeLogs },
  mixins: [accessMix],
  data: () => ({
    loading: false,
    tables: [
      { url: 'prisma', component: 'users', title: 'Prisma users', breadcrumbs: () => [{ name: 'Prisma', link: '/prisma' }] },
      {
        url: 'transactions',
        component: 'userTransactions',
        title: (params) => `${params.userLogin} transactions`,
        breadcrumbs: (params) => [
          { name: 'Prisma', link: '/prisma' },
          { name: params.userLogin, link: undefined },
        ],
      },
      {
        url: 'bets',
        component: 'userBets',
        title: (params) => `${params.userLogin} bets`,
        breadcrumbs: (params) => [
          { name: 'Prisma', link: '/prisma' },
          { name: params.userLogin, link: undefined },
        ],
      },
      {
        url: 'tasks',
        component: 'userTasks',
        title: (params) => `${params.userLogin} tasks`,
        breadcrumbs: (params) => [
          { name: 'Prisma', link: '/prisma' },
          { name: params.userLogin, link: undefined },
        ],
      },
      {
        url: 'task-details',
        component: 'taskDetails',
        title: (params) => `${params.userTaskId} task details`,
        breadcrumbs: (params) => [
          { name: 'Prisma', link: '/prisma' },
          { name: `${params.userLogin} tasks`, link: `/prisma/${params.userLogin}/${params.uuid}/tasks` },
          { name: `${params.userTaskId} task details`, link: undefined },
        ],
      },
      {
        url: 'prizes',
        component: 'userPrizes',
        title: (params) => `${params.userLogin} prizes`,
        breadcrumbs: (params) => [
          { name: 'Prisma', link: '/prisma' },
          { name: params.userLogin, link: undefined },
        ],
      },
      {
        url: 'prize-logs',
        component: 'prizeLogs',
        title: (params) => `${params.prizeId} prize logs`,
        breadcrumbs: (params) => [
          { name: 'Prisma', link: '/prisma' },
          { name: `${params.userLogin} prizes`, link: `/prisma/${params.userLogin}/prizes` },
          { name: `${params.prizeId} prize logs`, link: undefined },
        ],
      },
    ],
  }),
  computed: {
    ...mapState(['user']),
    permissionCreate() {
      return this.checkPermissions(this.user.permissions, 'prisma_users_info', 'create')
    },
    paymentsViewPermission() {
      return this.checkPermissions(this.user.permissions, 'prisma_users_payments', 'view')
    },
    paymentsEditPermission() {
      return this.checkPermissions(this.user.permissions, 'prisma_users_payments', 'edit')
    },
    permissionEdit() {
      return this.checkPermissions(this.user.permissions, 'prisma_users_info', 'edit')
    },
    userLoading() {
      return this.$store.state?.user?.loading || false
    },
    userLogin() {
      return this.$route.params.userLogin
    },
    userUUID() {
      return this.$route.params.uuid
    },
    userTaskId() {
      return this.$route.params.userTaskId
    },
    taskId() {
      return this.$route.params.taskId
    },
    prizeId() {
      return this.$route.params.prizeId
    },
    currentTitle() {
      if (this.currentPage) {
        const { title } = this.currentPage
        return typeof title === 'function'
          ? title(this.$route.params)
          : title
      }
      return 'Default Title'
    },
    currentPage() {
      const url = this.$route.path.split('/').pop()
      const matchedTable = this.tables.find((table) => table.url === url)
      return matchedTable
    },
    currentBreadcrumbs() {
      const url = this.$route.path.split('/').pop()
      if (url === 'prisma') {
        return false
      }
      const currentTable = this.tables.find((table) => table.url === url)

      if (currentTable && typeof currentTable.breadcrumbs === 'function') {
        return currentTable.breadcrumbs(this.$route.params)
      }

      return []
    },
    permissionOfSection() {
      if (this.currentPage.component === 'userPrizes') {
        return 'prisma_users_payments'
      }
      return 'prisma_users_info'
    },
  },
  created() {
  },
  methods: {
  },
}
</script>
  <style lang="scss">
  .prisma-users {
    &__link {
      color: #1890ff;
      &:hover {
        color: #40a9ff;
      }
    }
  }
  </style>
